export const candidates = [
  {
    position: "Secretary",
    house: "bandipur",
    menifesto:
      "https://docs.google.com/presentation/d/17COElQLbQYpBo_PGfoU8okpE1zw2UelfTetFKPmR8vY/edit?usp=sharing",
    email: "21f2000568@ds.study.iitm.ac.in",
    name: "Deependra Pratap Singh ",
    profilePhoto: "https://drive.google.com/open?id=18ekgDFaVWSVdDAE2tmDZ7ACi6PBKtxPv",
  },
  {
    menifesto: "https://drive.google.com/drive/folders/1-DVuSfnldbQMyUxJvjSx31sB4RXAeXhO",
    house: "sundarbans",
    position: "Secretary",
    profilePhoto: "https://drive.google.com/open?id=1ItSoGCZ3tr5KR9ypdvQXU-UqAc-3J548",
    email: "21f3002792@ds.study.iitm.ac.in",
    name: "Ravi Kant ",
  },
  {
    position: "Secretary",
    email: "21f3003023@ds.study.iitm.ac.in",
    menifesto:
      "https://docs.google.com/presentation/d/1KP6O0H-GTWXXWtz12_MXhYu35ZAPrDz9/edit?usp=sharing&ouid=102652706630076101589&rtpof=true&sd=true",
    name: "Somsankar Chakraborty",
    house: "nilgiri",
    profilePhoto: "https://drive.google.com/open?id=1OUEijsiANcXTnMVvzOsVV-pZ1na6TerD",
  },
  {
    house: "nallamala",
    menifesto: "https://t.ly/cTgTw",
    email: "22f2000108@ds.study.iitm.ac.in",

    name: "Naman Shyamsukha",
    position: "Secretary",
    profilePhoto: "https://drive.google.com/open?id=14iKssNcpgYLzToXNS3GBVUbCBrB3d4ED",
  },
  {
    menifesto:
      "https://www.canva.com/design/DAGNRl0Ta7I/J4Di_Ny2zaoTtVbrWZb_bQ/view?utm_content=DAGNRl0Ta7I&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    profilePhoto: "https://drive.google.com/open?id=1A9mI-7PqYzrERi5AXvziTE58eeHJS5Pp",
    house: "nallamala",
    name: "Manoranjan Mohanty",
    email: "22f2000631@ds.study.iitm.ac.in",
    position: "Deputy Secretary",
  },
  {
    profilePhoto: "https://drive.google.com/open?id=1j_lIMYFonkqdXlkhiAuRf_6_C9XzFfwy",
    position: "Deputy Secretary",
    name: "Abhay Kumar ",
    house: "nallamala",

    menifesto:
      "https://docs.google.com/document/d/1XM47U3FS2IIIPxe69AzntxLQbb3kVCnwEYUVNyQCyf0/edit?usp=sharing",
    email: "22f2000650@ds.study.iitm.ac.in",
  },
  {
    profilePhoto: "https://drive.google.com/open?id=1zMYtNpGYkPmbL8MLT1TK8pfTXjJ6vnjA",

    email: "22f2001536@ds.study.iitm.ac.in",
    house: "nallamala",
    name: "Siddharth Umathe ",
    position: "Secretary",
    menifesto: "https://sites.google.com/ds.study.iitm.ac.in/nallamala-house-secretary?usp=sharing",
  },
  {
    email: "22f3001480@ds.study.iitm.ac.in",
    house: "saranda",
    name: "Sidhaarth Shree",
    profilePhoto: "https://drive.google.com/open?id=1gDDRJkVXOSVxI49iBad0tqVZ1YPkuwcz",
    position: "Secretary",
    menifesto:
      "https://www.canva.com/design/DAGNFdlpRzQ/V-pApQYPL_J86fsUUprNrw/view?utm_content=DAGNFdlpRzQ&utm_campaign=designshare&utm_medium=link&utm_source=editor",
  },
  {
    name: "Shramadeep Debnath",
    email: "22f3001912@ds.study.iitm.ac.in",

    menifesto: "https://shramadeep-nilgiri.framer.website/",
    house: "nilgiri",
    profilePhoto: "https://drive.google.com/open?id=1BIUv2c9uyWJz5DPn5_ltHXQxLvtLGVqa",
    position: "Deputy Secretary",
  },
  {
    email: "22f3002129@ds.study.iitm.ac.in",
    position: "Secretary",
    menifesto:
      "https://drive.google.com/file/d/19aIFzWGr293rZtEkUSLEgnXTLQsD1a6g/view?usp=drivesdk",

    house: "corbett",
    name: "PAWAN MISHRA ",
    profilePhoto: "https://drive.google.com/open?id=107rjfPjfgGbGT-8OiBhTSNMPqZMUNaYG",
  },
  {
    email: "22f3002152@ds.study.iitm.ac.in",
    name: "Chatrapal Singh Rathore",
    profilePhoto: "https://drive.google.com/open?id=1-HcM6DE70CPKAuc8jscbTHFsPD8V6NTn",
    menifesto:
      "https://www.canva.com/design/DAGNSoQtNOQ/eeWVY6dNWN8-RnJ7PDpZfQ/view?utm_content=DAGNSoQtNOQ&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    house: "pichavaram",
    position: "Secretary",
  },
  {
    profilePhoto: "https://drive.google.com/open?id=19ugUiZVJf1fPPtkiQdqI86y0XN3BoLzG",

    house: "corbett",
    email: "22f3002198@ds.study.iitm.ac.in",
    name: "Harsh kumar",
    menifesto: "https://drive.google.com/file/d/116_kMBtHXnr_e9eyX76-YfEPHfc9U_Hj/view?usp=sharing",
    position: "Deputy Secretary",
  },
  {
    position: "Deputy Secretary",
    email: "22f3002289@ds.study.iitm.ac.in",

    profilePhoto: "https://drive.google.com/open?id=1mEBzZETms9e9M8RpTX9pUkbO1TfEnWN6",
    menifesto:
      "https://docs.google.com/document/d/1HjTjBNvkUoNUCj-tMhfo3XAKq3rjgIP8blVDEibi4Xs/edit?usp=sharing",
    name: "Mayank Verma",
    house: "namdapha",
  },
  {
    email: "22f3002914@ds.study.iitm.ac.in",
    position: "Deputy Secretary",
    menifesto: "https://shivakumar04.github.io/Shiva-kumar-s-manifesto/",
    profilePhoto: "https://drive.google.com/open?id=1H5owQTbrRhMxqbJGEQruNRB3UWLb-N-m",

    house: "kaziranga",
    name: "G. Shiva Kumar",
  },
  {
    profilePhoto: "https://drive.google.com/open?id=1EsUJOP5cZ7MB-9steZFP4bU3gNmBFhX7",
    house: "namdapha",
    email: "22f3003111@ds.study.iitm.ac.in",

    menifesto: "https://drive.google.com/file/d/1jDplSVDQSJKzZQIRFZu6hh9aZPRmXv9V/view?usp=sharing",
    name: "Pankaj Joshi",
    position: "Secretary",
  },
  {
    house: "saranda",
    email: "23f1000497@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1G5iDv5O1NvIbwNGto4krrZfQVDfuYDPZ",
    name: "Naveen Garg",

    position: "Deputy Secretary",
    menifesto:
      "https://www.canva.com/design/DAGNMRzIoZU/MbqdOzfSCJWuLkBXIPe7mQ/view?utm_content=DAGNMRzIoZU&utm_campaign=designshare&utm_medium=link&utm_source=editor",
  },
  {
    name: "Abhishek Saha",
    position: "Secretary",
    menifesto:
      "https://docs.google.com/presentation/d/1fqRhicAfRhMrkYlvqEQ5QjM98RcQmtTr-4UbCmZe7pw/edit?usp=sharing",
    house: "saranda",
    profilePhoto: "https://drive.google.com/open?id=1ZPElg5LbEKyiNmRlU9Vl8yEK3h7flYgU",

    email: "23f1001572@ds.study.iitm.ac.in",
  },
  {
    menifesto:
      "https://docs.google.com/presentation/d/1J-C21-rxC1OyVmjfWrkvz9w433Y_ImWK/edit?usp=drivesdk&ouid=113824035821294598183&rtpof=true&sd=true",
    name: "Aaradhya Sharma ",
    position: "Deputy Secretary",
    house: "sundarbans",
    email: "23f1001791@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1C58p1_y7OLtj3L5YavsQnanpKngQgF4p",
  },
  {
    house: "saranda",
    profilePhoto: "https://drive.google.com/open?id=1Yv2c4aL0OAiFpO763dWr-rZMUnqnwkKn",
    position: "Deputy Secretary",
    menifesto: "https://shorturl.at/OmZEL",

    email: "23f1002033@ds.study.iitm.ac.in",
    name: "Ishank Gupta ",
  },
  {
    profilePhoto: "https://drive.google.com/open?id=1f0jv8ztKQnQ3j9rF3vEmOd36VIxgBXcE",
    name: "Palkush Dave",
    menifesto: "https://sites.google.com/ds.study.iitm.ac.in/palkush",
    position: "Deputy Secretary",

    email: "23f1002209@ds.study.iitm.ac.in",
    house: "wayanad",
  },
  {
    house: "sundarbans",
    name: "Shreyansh Mall",

    position: "Secretary",
    menifesto: "https://sites.google.com/ds.study.iitm.ac.in/shreyanshmall",
    email: "23f1002638@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1OC249Eo_UtMpprZiTNMmtg82ich-nxYz",
  },
  {
    name: "Debanjan Sahoo",
    position: "Secretary",
    house: "nallamala",

    menifesto: "https://qrfy.io/cCwgkX60Nh",
    email: "23f2000223@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1S7TMvl2olqcUO_yvequomFcrw8Ex8vZf",
  },
  {
    name: "Shambhavi Shekhar",
    menifesto: "https://shambhavi-iitm.github.io",
    house: "wayanad",

    email: "23f2000348@ds.study.iitm.ac.in",
    position: "Secretary",
    profilePhoto: "https://drive.google.com/open?id=1Y2SLKg8Dh7Wbg7jXhC5FX-0w4RT46wpO",
  },
  {
    position: "Secretary",
    house: "kaziranga",
    menifesto: "",

    name: "Nitin Saini",
    profilePhoto: "https://drive.google.com/open?id=1dCKrwlt5av8UeUJ6Oj6FGrkO1sICHZgV",
    email: "23f2000450@ds.study.iitm.ac.in",
  },
  {
    house: "sundarbans",
    email: "23f2001123@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1_HolLp3d5Q873v5KqHAWPxRzlMBtMhCw",
    name: "Anshu Rajbhar",
    menifesto: "https://www.canva.com/design/DAGNcGbk4vQ/lIBMDyHUa7hyih5UaSl61A/edit",
    position: "Deputy Secretary",
  },
  {
    name: "SOUMYA MAZUMDAR",
    profilePhoto: "https://drive.google.com/open?id=1RNzrRDrnFOH7JnJ0J8L3vay4ZchCPV8_",
    position: "Secretary",
    menifesto: "https://tinyurl.com/34ryh3vc",

    email: "23f2002814@ds.study.iitm.ac.in",
    house: "corbett",
  },
  {
    name: "Sudhanshu Shekhar",

    house: "kanha",
    position: "Deputy Secretary",
    profilePhoto: "https://drive.google.com/open?id=16f4wutOJkwZ0QREda8Ans4TWx83q0jM2",
    menifesto:
      "https://www.canva.com/design/DAGNMU0ydw0/pgDIw7J6H_NsuHNzMlNxMg/view?utm_content=DAGNMU0ydw0&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    email: "23f2005067@ds.study.iitm.ac.in",
  },
  {
    name: "Aditya Rao",
    menifesto: "https://bit.ly/aditya-rao",

    profilePhoto: "https://drive.google.com/open?id=1ZDWHyJDodx-aAE4Oa2cMksnTydmQHA1h",
    house: "pichavaram",
    position: "Deputy Secretary",
    email: "23f3000019@es.study.iitm.ac.in",
  },
  {
    profilePhoto: "https://drive.google.com/open?id=1H0KvGbXHuitWINoSATgJLf_A1tBAlcqy",
    position: "Secretary",
    menifesto:
      "https://drive.google.com/file/d/1Q4eIElaOALn5_RMe6SDfIwrOLnB2gnml/view?usp=share_link",
    house: "sundarbans",
    email: "23f3000200@es.study.iitm.ac.in",
    name: "Namai Chandra ",
  },
  {
    position: "Deputy Secretary",

    house: "bandipur",
    menifesto:
      "https://www.canva.com/design/DAGNRyc0v78/AR1n6tGBtgeHljkrRg3DAA/view?utm_content=DAGNRyc0v78&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    email: "23f3000211@es.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1a9nG7_rPAI4ztQO6Nq_3908jFC5N0i0H",
    name: "Param kumar",
  },
  {
    position: "Secretary",
    menifesto: "https://rb.gy/x7oq4q",
    email: "23f3000253@ds.study.iitm.ac.in",
    name: "Aadya Sharma",
    house: "kanha",

    profilePhoto: "https://drive.google.com/open?id=1OfmIgtk8BepI60K9a_84Di4VBb-UkSOD",
  },
  {
    name: "Vivek Dhariwal",
    menifesto: "https://drive.google.com/file/d/1VR7IdvwznjrMlEOginOOfswkFbuqt_ZZ/view?usp=sharing",
    email: "23f3000338@es.study.iitm.ac.in",
    position: "Secretary",
    house: "saranda",
    profilePhoto: "https://drive.google.com/open?id=18-Pvxl7d95DCZuRbZerMgr8OTvAYCxsi",
  },
  {
    position: "Secretary",
    house: "kanha",
    profilePhoto: "https://drive.google.com/open?id=12y7ecsOSoDaDuz3Ouc3jH3u5D1zPfXz_",
    name: "Tushti ",
    menifesto:
      "https://www.canva.com/design/DAGNqbgPA1Y/zptnwZDh5iCsZpsbK4f8Jg/view?utm_content=DAGNqbgPA1Y&utm_campaign=designshare&utm_medium=link&utm_source=editor",

    email: "23f3000788@ds.study.iitm.ac.in",
  },
  {
    house: "gir",
    position: "Secretary",

    menifesto: "https://www.canva.com/design/DAGNloNuBhg/SPDD1twvMHq6-UI7m2cF-Q/edit",
    name: "Yatharth",
    email: "23f3001171@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1Bt8lQrJ6MgKoe7lt7KAjlWTEz1Lh5iV-",
  },
  {
    position: "Deputy Secretary",

    profilePhoto: "https://drive.google.com/open?id=1AehW7mcuO-fB-4B2uUNGP0fg1d0-gKFT",
    email: "23f3002351@ds.study.iitm.ac.in",
    menifesto: "https://sites.google.com/ds.study.iitm.ac.in/nikhil-manifesto/home",
    house: "nallamala",
    name: "Nikhil Kumar",
  },
  {
    profilePhoto: "https://drive.google.com/open?id=1zl3KqxZqSguqAaHqa7CUISsTpyE8Pyp5",
    menifesto: "https://sites.google.com/ds.study.iitm.ac.in/shashwatupadhyay/home",
    house: "nallamala",
    email: "23f3002413@ds.study.iitm.ac.in",
    name: "Shashwat Upadhyay ",
    position: "Secretary",
  },
  {
    name: "Virendra Jain",
    profilePhoto: "https://drive.google.com/open?id=1f3iRdqEqSVo9xpKGZw7iV1I-4ZX2g_i_",
    house: "gir",
    email: "23f3004123@ds.study.iitm.ac.in",
    position: "Deputy Secretary",
    menifesto:
      "https://www.canva.com/design/DAGNSKfCSc0/fiVsjk4keXcubFv5JNi6zw/view?utm_content=DAGNSKfCSc0&utm_campaign=designshare&utm_medium=link&utm_source=editor",
  },
];
