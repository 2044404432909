import { useState, useLayoutEffect, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import HomePage from "pages/home";
import ElectionsPage from "pages/elections";
import LoginPage from "pages/login";
import ProfilePage from "pages/profile";
import Authenticate from "components/Auth";
import ResultsPage from "pages/results";
import CandidatesPage from "pages/canididates";

import { AppContext } from "contexts/app";

import { parseSessionData } from "helpers/auth";

import { onMessageListener } from "apis/firebase";

import "./App.css";
import Voting from "components/Voting";
import VotingClosed from "components/VotingClosed";
import VotePage from "pages/vote";
import UHCResutlts from "pages/uhcResults";

const defaultState = { loading: false, liveData: {} };
function App() {
  const [session, setSession] = useState({ ...defaultState, loading: true });
  useLayoutEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      let data = { ...defaultState };
      if (user) {
        data = { ...defaultState, ...parseSessionData(user), loading: false };
      }
      setSession(data);
    });
  }, []);

  const curDate = new Date();
  const after12 = new Date(2024, 7, 14, 11);

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        const {
          notification: { title, body },
        } = payload;
        alert(`${title}\n${body}`);
      })
      .catch((err) => console.err(err));
  });

  return (
    <div className="App">
      <AppContext.Provider value={{ session, setSession }}>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/elections"
              render={(routeProps) => (
                <Authenticate>
                  <ElectionsPage {...routeProps} />
                </Authenticate>
              )}
            />
            <Route exact path="/login" render={(routeProps) => <LoginPage {...routeProps} />} />
            <Route
              exact
              path="/profile"
              render={(routeProps) => (
                <Authenticate>
                  <ProfilePage {...routeProps} />
                </Authenticate>
              )}
            />
            {/* <Route */}
            {/*   exact */}
            {/*   path="/candidates" */}
            {/*   render={(routeProps) => ( */}
            {/*     <Authenticate> */}
            {/*       <CandidatesPage {...routeProps} /> */}
            {/*     </Authenticate> */}
            {/*   )} */}
            {/* /> */}
            <Route
              exact
              path="/vote"
              render={(routeProps) => (
                <Authenticate>
                  {curDate > after12 ? (
                    <Voting {...routeProps} />
                  ) : (
                    <VotingClosed {...routeProps} />
                  )}
                </Authenticate>
              )}
            />
            <Route
              exact
              path="/uhc-election-2024"
              render={(routeProps) => (
                <Authenticate>
                  <ElectionsPage {...routeProps} />
                </Authenticate>
              )}
            />
            <Route
              exact
              path="/"
              render={(routeProps) => (
                <Authenticate>
                  <HomePage {...routeProps} />
                </Authenticate>
              )}
            />
            <Route
              exact
              path="/results"
              render={(routeProps) => (
                <Authenticate>
                  <UHCResutlts {...routeProps} />
                </Authenticate>
              )}
            />
            {/* <Route exact path="/" render={(routeProps) => <MaintenancePage {...routeProps} />} /> */}
            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </AppContext.Provider>
    </div>
  );
}

export default App;
