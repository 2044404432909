export const uhcResults = {
  bandipur: [
    {
      menifesto:
        "https://docs.google.com/presentation/d/17COElQLbQYpBo_PGfoU8okpE1zw2UelfTetFKPmR8vY/edit?usp=sharing",
      position: "Secretary",
      profilePhoto: "https://drive.google.com/open?id=18ekgDFaVWSVdDAE2tmDZ7ACi6PBKtxPv",
      house: "bandipur",
      name: "Deependra Pratap Singh",
      email: "21f2000568@ds.study.iitm.ac.in",
      votes: 112,
    },
    {
      profilePhoto: "https://drive.google.com/open?id=1a9nG7_rPAI4ztQO6Nq_3908jFC5N0i0H",
      email: "23f3000211@es.study.iitm.ac.in",
      name: "Param kumar",
      votes: 91,
      house: "bandipur",
      position: "Deputy Secretary",
      menifesto:
        "https://www.canva.com/design/DAGNRyc0v78/AR1n6tGBtgeHljkrRg3DAA/view?utm_content=DAGNRyc0v78&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    },
  ],
  sundarbans: [
    {
      name: "Aaradhya Sharma",
      profilePhoto: "https://drive.google.com/open?id=1C58p1_y7OLtj3L5YavsQnanpKngQgF4p",
      votes: 125,
      house: "sundarbans",
      menifesto:
        "https://docs.google.com/presentation/d/1J-C21-rxC1OyVmjfWrkvz9w433Y_ImWK/edit?usp=drivesdk&ouid=113824035821294598183&rtpof=true&sd=true",
      position: "Deputy Secretary",
      email: "23f1001791@ds.study.iitm.ac.in",
    },
    {
      profilePhoto: "https://drive.google.com/open?id=1If67WHXHaPyDwKq-pwwvFJbw2gcz88GP",
      votes: 159,
      house: "sundarbans",
      name: "Shreyansh Mall",
      menifesto: "https://sites.google.com/ds.study.iitm.ac.in/shreyanshmall",
      email: "23f1002638@ds.study.iitm.ac.in",
      position: "Secretary",
    },
  ],
  nilgiri: [
    {
      email: "21f3003023@ds.study.iitm.ac.in",
      profilePhoto: "https://drive.google.com/open?id=1OUEijsiANcXTnMVvzOsVV-pZ1na6TerD",
      name: "Somsankar Chakraborty",
      house: "nilgiri",
      votes: 82,
      menifesto:
        "https://docs.google.com/presentation/d/1KP6O0H-GTWXXWtz12_MXhYu35ZAPrDz9/edit?usp=sharing&ouid=102652706630076101589&rtpof=true&sd=true",
      position: "Secretary",
    },
    {
      email: "22f3001912@ds.study.iitm.ac.in",
      profilePhoto: "https://drive.google.com/open?id=1BIUv2c9uyWJz5DPn5_ltHXQxLvtLGVqa",
      name: "Shramadeep Debnath",
      menifesto: "https://shramadeep-nilgiri.framer.website/",
      votes: 71,
      position: "Deputy Secretary",
      house: "nilgiri",
    },
  ],
  nallamala: [
    {
      name: "Naman Shyamsukha",
      email: "22f2000108@ds.study.iitm.ac.in",
      menifesto: "https://t.ly/cTgTw",
      position: "Secretary",
      profilePhoto: "https://drive.google.com/open?id=14iKssNcpgYLzToXNS3GBVUbCBrB3d4ED",
      house: "nallamala",
      votes: 148,
    },
    {
      profilePhoto: "https://drive.google.com/open?id=1j_lIMYFonkqdXlkhiAuRf_6_C9XzFfwy",
      votes: 149,
      house: "nallamala",
      name: "Abhay Kumar",
      email: "22f2000650@ds.study.iitm.ac.in",
      menifesto:
        "https://docs.google.com/document/d/1XM47U3FS2IIIPxe69AzntxLQbb3kVCnwEYUVNyQCyf0/edit?usp=sharing",
      position: "Deputy Secretary",
    },
  ],
  saranda: [
    {
      email: "23f1001572@ds.study.iitm.ac.in",
      position: "Secretary",
      name: "Abhishek Saha",
      menifesto:
        "https://docs.google.com/presentation/d/1fqRhicAfRhMrkYlvqEQ5QjM98RcQmtTr-4UbCmZe7pw/edit?usp=sharing",
      votes: 164,
      house: "saranda",
      profilePhoto: "https://drive.google.com/open?id=1ZPElg5LbEKyiNmRlU9Vl8yEK3h7flYgU",
    },
    {
      house: "saranda",
      name: "Ishank Gupta ",
      votes: 149,
      profilePhoto: "https://drive.google.com/open?id=1Yv2c4aL0OAiFpO763dWr-rZMUnqnwkKn",
      position: "Deputy Secretary",
      menifesto: "https://shorturl.at/OmZEL",
      email: "23f1002033@ds.study.iitm.ac.in",
    },
  ],
  corbett: [
    {
      position: "Secretary",
      menifesto:
        "https://drive.google.com/file/d/19aIFzWGr293rZtEkUSLEgnXTLQsD1a6g/view?usp=drivesdk",
      profilePhoto: "https://drive.google.com/open?id=107rjfPjfgGbGT-8OiBhTSNMPqZMUNaYG",
      email: "22f3002129@ds.study.iitm.ac.in",
      votes: 73,
      house: "corbett",
      name: "PAWAN MISHRA ",
    },
    {
      position: "Deputy Secretary",
      votes: 68,
      menifesto:
        "https://drive.google.com/file/d/116_kMBtHXnr_e9eyX76-YfEPHfc9U_Hj/view?usp=sharing",
      house: "corbett",
      email: "22f3002198@ds.study.iitm.ac.in",
      profilePhoto: "https://drive.google.com/open?id=19ugUiZVJf1fPPtkiQdqI86y0XN3BoLzG",
      name: "Harsh kumar",
    },
  ],
  pichavaram: [
    {
      menifesto:
        "https://www.canva.com/design/DAGNSoQtNOQ/eeWVY6dNWN8-RnJ7PDpZfQ/view?utm_content=DAGNSoQtNOQ&utm_campaign=designshare&utm_medium=link&utm_source=editor",
      votes: 261,
      profilePhoto: "https://drive.google.com/open?id=1-HcM6DE70CPKAuc8jscbTHFsPD8V6NTn",
      position: "Secretary",
      email: "22f3002152@ds.study.iitm.ac.in",
      house: "pichavaram",
      name: "Chatrapal Singh Rathore",
    },
    {
      house: "pichavaram",
      position: "Deputy Secretary",
      email: "23f3000019@es.study.iitm.ac.in",
      votes: 250,
      name: "Aditya Rao",
      profilePhoto: "https://drive.google.com/open?id=1ZDWHyJDodx-aAE4Oa2cMksnTydmQHA1h",
      menifesto: "https://bit.ly/aditya-rao",
    },
  ],
  namdapha: [
    {
      name: "Mayank Verma",
      menifesto:
        "https://docs.google.com/document/d/1HjTjBNvkUoNUCj-tMhfo3XAKq3rjgIP8blVDEibi4Xs/edit?usp=sharing",
      profilePhoto: "https://drive.google.com/open?id=1mEBzZETms9e9M8RpTX9pUkbO1TfEnWN6",
      house: "namdapha",
      email: "22f3002289@ds.study.iitm.ac.in",
      position: "Deputy Secretary",
      votes: 88,
    },
    {
      menifesto:
        "https://drive.google.com/file/d/1jDplSVDQSJKzZQIRFZu6hh9aZPRmXv9V/view?usp=sharing",
      profilePhoto: "https://drive.google.com/open?id=1EsUJOP5cZ7MB-9steZFP4bU3gNmBFhX7",
      house: "namdapha",
      votes: 88,
      position: "Secretary",
      name: "Pankaj Joshi",
      email: "22f3003111@ds.study.iitm.ac.in",
    },
  ],
  kaziranga: [
    {
      house: "kaziranga",
      votes: 43,
      position: "Deputy Secretary",
      email: "22f3002914@ds.study.iitm.ac.in",
      profilePhoto: "https://drive.google.com/open?id=1H5owQTbrRhMxqbJGEQruNRB3UWLb-N-m",
      menifesto: "https://shivakumar04.github.io/Shiva-kumar-s-manifesto/",
      name: "G. Shiva Kumar",
    },
    {
      menifesto: "",
      house: "kaziranga",
      email: "23f2000450@ds.study.iitm.ac.in",
      profilePhoto: "https://drive.google.com/open?id=1dCKrwlt5av8UeUJ6Oj6FGrkO1sICHZgV",
      name: "Nitin Saini",
      position: "Secretary",
      votes: 37,
    },
  ],
  wayanad: [
    {
      votes: 120,
      house: "wayanad",
      menifesto: "https://sites.google.com/ds.study.iitm.ac.in/palkush",
      name: "Palkush Dave",
      position: "Deputy Secretary",
      email: "23f1002209@ds.study.iitm.ac.in",
      profilePhoto: "https://drive.google.com/open?id=1f0jv8ztKQnQ3j9rF3vEmOd36VIxgBXcE",
    },
    {
      position: "Secretary",
      votes: 139,
      email: "23f2000348@ds.study.iitm.ac.in",
      profilePhoto: "https://drive.google.com/open?id=1Y2SLKg8Dh7Wbg7jXhC5FX-0w4RT46wpO",
      name: "Shambhavi Shekhar",
      house: "wayanad",
      menifesto: "https://shambhavi-iitm.github.io",
    },
  ],
  kanha: [
    {
      votes: 255,
      email: "23f2005067@ds.study.iitm.ac.in",
      position: "Deputy Secretary",
      menifesto:
        "https://www.canva.com/design/DAGNMU0ydw0/pgDIw7J6H_NsuHNzMlNxMg/view?utm_content=DAGNMU0ydw0&utm_campaign=designshare&utm_medium=link&utm_source=editor",
      name: "Sudhanshu Shekhar",
      profilePhoto: "https://drive.google.com/open?id=16f4wutOJkwZ0QREda8Ans4TWx83q0jM2",
      house: "kanha",
    },
    {
      votes: 195,
      profilePhoto: "https://drive.google.com/open?id=12y7ecsOSoDaDuz3Ouc3jH3u5D1zPfXz_",
      position: "Secretary",
      menifesto:
        "https://www.canva.com/design/DAGNqbgPA1Y/zptnwZDh5iCsZpsbK4f8Jg/view?utm_content=DAGNqbgPA1Y&utm_campaign=designshare&utm_medium=link&utm_source=editor",
      house: "kanha",
      email: "23f3000788@ds.study.iitm.ac.in",
      name: "Tushti ",
    },
  ],
  gir: [
    {
      menifesto: "https://www.canva.com/design/DAGNloNuBhg/SPDD1twvMHq6-UI7m2cF-Q/edit",
      position: "Secretary",
      name: "Yatharth",
      email: "23f3001171@ds.study.iitm.ac.in",
      votes: 166,
      profilePhoto: "https://drive.google.com/open?id=1Bt8lQrJ6MgKoe7lt7KAjlWTEz1Lh5iV-",
      house: "gir",
    },
    {
      menifesto:
        "https://www.canva.com/design/DAGNSKfCSc0/fiVsjk4keXcubFv5JNi6zw/view?utm_content=DAGNSKfCSc0&utm_campaign=designshare&utm_medium=link&utm_source=editor",
      position: "Deputy Secretary",
      house: "gir",
      votes: 132,
      profilePhoto: "https://drive.google.com/open?id=1f3iRdqEqSVo9xpKGZw7iV1I-4ZX2g_i_",
      email: "23f3004123@ds.study.iitm.ac.in",
      name: "Virendra Jain",
    },
  ],
};

export const uhcResultsArr = [
  {
    menifesto:
      "https://docs.google.com/presentation/d/17COElQLbQYpBo_PGfoU8okpE1zw2UelfTetFKPmR8vY/edit?usp=sharing",
    position: "Secretary",
    profilePhoto: "https://drive.google.com/open?id=18ekgDFaVWSVdDAE2tmDZ7ACi6PBKtxPv",
    house: "bandipur",
    name: "Deependra Pratap Singh ",
    email: "21f2000568@ds.study.iitm.ac.in",
    votes: 112,
  },
  {
    email: "21f3003023@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1OUEijsiANcXTnMVvzOsVV-pZ1na6TerD",
    name: "Somsankar Chakraborty",
    house: "nilgiri",
    votes: 82,
    menifesto:
      "https://docs.google.com/presentation/d/1KP6O0H-GTWXXWtz12_MXhYu35ZAPrDz9/edit?usp=sharing&ouid=102652706630076101589&rtpof=true&sd=true",
    position: "Secretary",
  },

  {
    house: "sundarbans",
    position: "Secretary",
    votes: 70,
    email: "21f3002792@ds.study.iitm.ac.in",
    menifesto: "https://drive.google.com/drive/folders/1-DVuSfnldbQMyUxJvjSx31sB4RXAeXhO",
    name: "Ravi Kant ",
    profilePhoto: "https://drive.google.com/open?id=1ItSoGCZ3tr5KR9ypdvQXU-UqAc-3J548",
  },
  {
    name: "Naman Shyamsukha",
    email: "22f2000108@ds.study.iitm.ac.in",
    menifesto: "https://t.ly/cTgTw",
    position: "Secretary",
    profilePhoto: "https://drive.google.com/open?id=14iKssNcpgYLzToXNS3GBVUbCBrB3d4ED",
    house: "nallamala",
    votes: 148,
  },
  {
    house: "nallamala",
    name: "Manoranjan Mohanty",
    email: "22f2000631@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1A9mI-7PqYzrERi5AXvziTE58eeHJS5Pp",
    votes: 29,
    position: "Deputy Secretary",
    menifesto:
      "https://www.canva.com/design/DAGNRl0Ta7I/J4Di_Ny2zaoTtVbrWZb_bQ/view?utm_content=DAGNRl0Ta7I&utm_campaign=designshare&utm_medium=link&utm_source=editor",
  },
  {
    profilePhoto: "https://drive.google.com/open?id=1j_lIMYFonkqdXlkhiAuRf_6_C9XzFfwy",
    votes: 149,
    house: "nallamala",
    name: "Abhay Kumar ",
    email: "22f2000650@ds.study.iitm.ac.in",
    menifesto:
      "https://docs.google.com/document/d/1XM47U3FS2IIIPxe69AzntxLQbb3kVCnwEYUVNyQCyf0/edit?usp=sharing",
    position: "Deputy Secretary",
  },
  {
    profilePhoto: "https://drive.google.com/open?id=1zMYtNpGYkPmbL8MLT1TK8pfTXjJ6vnjA",
    position: "Secretary",
    name: "Siddharth Umathe ",
    menifesto: "https://sites.google.com/ds.study.iitm.ac.in/nallamala-house-secretary?usp=sharing",
    house: "nallamala",
    votes: 36,
    email: "22f2001536@ds.study.iitm.ac.in",
  },
  {
    position: "Secretary",
    name: "Sidhaarth Shree",
    profilePhoto: "https://drive.google.com/open?id=1gDDRJkVXOSVxI49iBad0tqVZ1YPkuwcz",
    house: "saranda",
    email: "22f3001480@ds.study.iitm.ac.in",
    votes: 114,
    menifesto:
      "https://www.canva.com/design/DAGNFdlpRzQ/V-pApQYPL_J86fsUUprNrw/view?utm_content=DAGNFdlpRzQ&utm_campaign=designshare&utm_medium=link&utm_source=editor",
  },
  {
    email: "22f3001912@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1BIUv2c9uyWJz5DPn5_ltHXQxLvtLGVqa",
    name: "Shramadeep Debnath",
    menifesto: "https://shramadeep-nilgiri.framer.website/",
    votes: 71,
    position: "Deputy Secretary",
    house: "nilgiri",
  },
  {
    position: "Secretary",
    menifesto:
      "https://drive.google.com/file/d/19aIFzWGr293rZtEkUSLEgnXTLQsD1a6g/view?usp=drivesdk",
    profilePhoto: "https://drive.google.com/open?id=107rjfPjfgGbGT-8OiBhTSNMPqZMUNaYG",
    email: "22f3002129@ds.study.iitm.ac.in",
    votes: 73,
    house: "corbett",
    name: "PAWAN MISHRA ",
  },
  {
    menifesto:
      "https://www.canva.com/design/DAGNSoQtNOQ/eeWVY6dNWN8-RnJ7PDpZfQ/view?utm_content=DAGNSoQtNOQ&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    votes: 261,
    profilePhoto: "https://drive.google.com/open?id=1-HcM6DE70CPKAuc8jscbTHFsPD8V6NTn",
    position: "Secretary",
    email: "22f3002152@ds.study.iitm.ac.in",
    house: "pichavaram",
    name: "Chatrapal Singh Rathore",
  },
  {
    position: "Deputy Secretary",
    votes: 68,
    menifesto: "https://drive.google.com/file/d/116_kMBtHXnr_e9eyX76-YfEPHfc9U_Hj/view?usp=sharing",
    house: "corbett",
    email: "22f3002198@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=19ugUiZVJf1fPPtkiQdqI86y0XN3BoLzG",
    name: "Harsh kumar",
  },
  {
    name: "Mayank Verma",
    menifesto:
      "https://docs.google.com/document/d/1HjTjBNvkUoNUCj-tMhfo3XAKq3rjgIP8blVDEibi4Xs/edit?usp=sharing",
    profilePhoto: "https://drive.google.com/open?id=1mEBzZETms9e9M8RpTX9pUkbO1TfEnWN6",
    house: "namdapha",
    email: "22f3002289@ds.study.iitm.ac.in",
    position: "Deputy Secretary",
    votes: 88,
  },
  {
    house: "kaziranga",
    votes: 43,
    position: "Deputy Secretary",
    email: "22f3002914@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1H5owQTbrRhMxqbJGEQruNRB3UWLb-N-m",
    menifesto: "https://shivakumar04.github.io/Shiva-kumar-s-manifesto/",
    name: "G. Shiva Kumar",
  },
  {
    menifesto: "https://drive.google.com/file/d/1jDplSVDQSJKzZQIRFZu6hh9aZPRmXv9V/view?usp=sharing",
    profilePhoto: "https://drive.google.com/open?id=1EsUJOP5cZ7MB-9steZFP4bU3gNmBFhX7",
    house: "namdapha",
    votes: 88,
    position: "Secretary",
    name: "Pankaj Joshi",
    email: "22f3003111@ds.study.iitm.ac.in",
  },
  {
    email: "23f1000497@ds.study.iitm.ac.in",
    name: "Naveen Garg",
    position: "Deputy Secretary",
    profilePhoto: "https://drive.google.com/open?id=1G5iDv5O1NvIbwNGto4krrZfQVDfuYDPZ",
    menifesto:
      "https://www.canva.com/design/DAGNMRzIoZU/MbqdOzfSCJWuLkBXIPe7mQ/view?utm_content=DAGNMRzIoZU&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    votes: 139,
    house: "saranda",
  },
  {
    email: "23f1001572@ds.study.iitm.ac.in",
    position: "Secretary",
    name: "Abhishek Saha",
    menifesto:
      "https://docs.google.com/presentation/d/1fqRhicAfRhMrkYlvqEQ5QjM98RcQmtTr-4UbCmZe7pw/edit?usp=sharing",
    votes: 164,
    house: "saranda",
    profilePhoto: "https://drive.google.com/open?id=1ZPElg5LbEKyiNmRlU9Vl8yEK3h7flYgU",
  },
  {
    name: "Aaradhya Sharma ",
    profilePhoto: "https://drive.google.com/open?id=1C58p1_y7OLtj3L5YavsQnanpKngQgF4p",
    votes: 125,
    house: "sundarbans",
    menifesto:
      "https://docs.google.com/presentation/d/1J-C21-rxC1OyVmjfWrkvz9w433Y_ImWK/edit?usp=drivesdk&ouid=113824035821294598183&rtpof=true&sd=true",
    position: "Deputy Secretary",
    email: "23f1001791@ds.study.iitm.ac.in",
  },
  {
    house: "saranda",
    name: "Ishank Gupta ",
    votes: 149,
    profilePhoto: "https://drive.google.com/open?id=1Yv2c4aL0OAiFpO763dWr-rZMUnqnwkKn",
    position: "Deputy Secretary",
    menifesto: "https://shorturl.at/OmZEL",
    email: "23f1002033@ds.study.iitm.ac.in",
  },
  {
    votes: 120,
    house: "wayanad",
    menifesto: "https://sites.google.com/ds.study.iitm.ac.in/palkush",
    name: "Palkush Dave",
    position: "Deputy Secretary",
    email: "23f1002209@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1f0jv8ztKQnQ3j9rF3vEmOd36VIxgBXcE",
  },
  {
    profilePhoto: "https://drive.google.com/open?id=1If67WHXHaPyDwKq-pwwvFJbw2gcz88GP",
    votes: 159,
    house: "sundarbans",
    name: "Shreyansh Mall",
    menifesto: "https://sites.google.com/ds.study.iitm.ac.in/shreyanshmall",
    email: "23f1002638@ds.study.iitm.ac.in",
    position: "Secretary",
  },
  {
    house: "nallamala",
    position: "Secretary",
    name: "Debanjan Sahoo",
    votes: 16,
    menifesto: "https://qrfy.io/cCwgkX60Nh",
    profilePhoto: "https://drive.google.com/open?id=1S7TMvl2olqcUO_yvequomFcrw8Ex8vZf",
    email: "23f2000223@ds.study.iitm.ac.in",
  },
  {
    position: "Secretary",
    votes: 139,
    email: "23f2000348@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1Y2SLKg8Dh7Wbg7jXhC5FX-0w4RT46wpO",
    name: "Shambhavi Shekhar",
    house: "wayanad",
    menifesto: "https://shambhavi-iitm.github.io",
  },
  {
    menifesto: "",
    house: "kaziranga",
    email: "23f2000450@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1dCKrwlt5av8UeUJ6Oj6FGrkO1sICHZgV",
    name: "Nitin Saini",
    position: "Secretary",
    votes: 37,
  },
  {
    menifesto: "https://www.canva.com/design/DAGNcGbk4vQ/lIBMDyHUa7hyih5UaSl61A/edit",
    profilePhoto: "https://drive.google.com/open?id=1RqWKNIMjYb4pEkuNbCZBk-neASwvnLTy",
    votes: 118,
    house: "sundarbans",
    email: "23f2001123@ds.study.iitm.ac.in",
    name: "Anshu Rajbhar",
    position: "Deputy Secretary",
  },
  {
    email: "23f2002814@ds.study.iitm.ac.in",
    votes: 56,
    name: "SOUMYA MAZUMDAR",
    profilePhoto: "https://drive.google.com/open?id=1RNzrRDrnFOH7JnJ0J8L3vay4ZchCPV8_",
    menifesto: "https://tinyurl.com/34ryh3vc",
    position: "Secretary",
    house: "corbett",
  },
  {
    votes: 255,
    email: "23f2005067@ds.study.iitm.ac.in",
    position: "Deputy Secretary",
    menifesto:
      "https://www.canva.com/design/DAGNMU0ydw0/pgDIw7J6H_NsuHNzMlNxMg/view?utm_content=DAGNMU0ydw0&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    name: "Sudhanshu Shekhar",
    profilePhoto: "https://drive.google.com/open?id=16f4wutOJkwZ0QREda8Ans4TWx83q0jM2",
    house: "kanha",
  },
  {
    house: "pichavaram",
    position: "Deputy Secretary",
    email: "23f3000019@es.study.iitm.ac.in",
    votes: 250,
    name: "Aditya Rao",
    profilePhoto: "https://drive.google.com/open?id=1ZDWHyJDodx-aAE4Oa2cMksnTydmQHA1h",
    menifesto: "https://bit.ly/aditya-rao",
  },
  {
    name: "Namai Chandra ",
    position: "Secretary",
    menifesto:
      "https://drive.google.com/file/d/1Q4eIElaOALn5_RMe6SDfIwrOLnB2gnml/view?usp=share_link",
    votes: 44,
    email: "23f3000200@es.study.iitm.ac.in",
    house: "sundarbans",
    profilePhoto: "https://drive.google.com/open?id=1H0KvGbXHuitWINoSATgJLf_A1tBAlcqy",
  },
  {
    profilePhoto: "https://drive.google.com/open?id=1a9nG7_rPAI4ztQO6Nq_3908jFC5N0i0H",
    email: "23f3000211@es.study.iitm.ac.in",
    name: "Param kumar",
    votes: 91,
    house: "bandipur",
    position: "Deputy Secretary",
    menifesto:
      "https://www.canva.com/design/DAGNRyc0v78/AR1n6tGBtgeHljkrRg3DAA/view?utm_content=DAGNRyc0v78&utm_campaign=designshare&utm_medium=link&utm_source=editor",
  },
  {
    email: "23f3000253@ds.study.iitm.ac.in",
    profilePhoto: "https://drive.google.com/open?id=1OfmIgtk8BepI60K9a_84Di4VBb-UkSOD",
    votes: 179,
    house: "kanha",
    position: "Secretary",
    name: "Aadya Sharma",
    menifesto: "https://rb.gy/x7oq4q",
  },
  {
    menifesto: "https://drive.google.com/file/d/1VR7IdvwznjrMlEOginOOfswkFbuqt_ZZ/view?usp=sharing",
    position: "Secretary",
    profilePhoto: "https://drive.google.com/open?id=18-Pvxl7d95DCZuRbZerMgr8OTvAYCxsi",
    name: "Vivek Dhariwal",
    email: "23f3000338@es.study.iitm.ac.in",
    votes: 25,
    house: "saranda",
  },
  {
    votes: 195,
    profilePhoto: "https://drive.google.com/open?id=12y7ecsOSoDaDuz3Ouc3jH3u5D1zPfXz_",
    position: "Secretary",
    menifesto:
      "https://www.canva.com/design/DAGNqbgPA1Y/zptnwZDh5iCsZpsbK4f8Jg/view?utm_content=DAGNqbgPA1Y&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    house: "kanha",
    email: "23f3000788@ds.study.iitm.ac.in",
    name: "Tushti ",
  },
  {
    menifesto: "https://www.canva.com/design/DAGNloNuBhg/SPDD1twvMHq6-UI7m2cF-Q/edit",
    position: "Secretary",
    name: "Yatharth",
    email: "23f3001171@ds.study.iitm.ac.in",
    votes: 166,
    profilePhoto: "https://drive.google.com/open?id=1Bt8lQrJ6MgKoe7lt7KAjlWTEz1Lh5iV-",
    house: "gir",
  },
  {
    house: "nallamala",
    name: "Nikhil Kumar",
    profilePhoto: "https://drive.google.com/open?id=1AehW7mcuO-fB-4B2uUNGP0fg1d0-gKFT",
    email: "23f3002351@ds.study.iitm.ac.in",
    position: "Deputy Secretary",
    menifesto: "https://sites.google.com/ds.study.iitm.ac.in/nikhil-manifesto/home",
    votes: 66,
  },
  {
    menifesto: "https://sites.google.com/ds.study.iitm.ac.in/shashwatupadhyay/home",
    votes: 23,
    position: "Secretary",
    profilePhoto: "https://drive.google.com/open?id=1zl3KqxZqSguqAaHqa7CUISsTpyE8Pyp5",
    email: "23f3002413@ds.study.iitm.ac.in",
    name: "Shashwat Upadhyay ",
    house: "nallamala",
  },
  {
    menifesto:
      "https://www.canva.com/design/DAGNSKfCSc0/fiVsjk4keXcubFv5JNi6zw/view?utm_content=DAGNSKfCSc0&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    position: "Deputy Secretary",
    house: "gir",
    votes: 132,
    profilePhoto: "https://drive.google.com/open?id=1f3iRdqEqSVo9xpKGZw7iV1I-4ZX2g_i_",
    email: "23f3004123@ds.study.iitm.ac.in",
    name: "Virendra Jain",
  },
];

// sort the array by votes
// export let sortedResults = {};
//
// uhcResults.forEach((result) => {
//   if (sortedResults[result.house]) {
//     sortedResults[result.house].push(result);
//   } else {
//     sortedResults[result.house] = [result];
//   }
// });
