import React from "react";
import { Container } from "react-bootstrap";
import Layout from "./Layout";

const VotingClosed = () => {
  return (
    <Layout>
      <div className="bg-color-maroon p-5 w-100 display-5 vh-100 d-flex justify-content-center align-items-center">
        <div className="mb-10">Voting is closed till Aug, 14, 12:00 PM</div>
      </div>
    </Layout>
  );
};

export default VotingClosed;
