import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import Layout from "./Layout";
import { getProfileDetails, voteElectedCandidates } from "apis/firebase";
import { AppContext } from "contexts/app";
import { candidates } from "resources/electionNominies";

// Setting the Timeline Range
const currDate = new Date();
const startDate = new Date(2024, 7, 17, 8, 0, 0);
const endDate = new Date(2024, 7, 17, 23, 59, 59);

// House Phase Division
// function PhaseSelection() {
//   if (currDate < new Date(2024, 7, 14, 23, 59, 59)) {
//     return ["Bandipur", "Corbett", "Gir", "Kanha"];
//   } else if (
//     currDate >= new Date(2024, 7, 14, 23, 59, 59) &&
//     currDate < new Date(2024, 7, 15, 23, 59, 59)
//   ) {
//     return ["Kaziranga", "Nallamala", "Namdapha", "Nilgiri"];
//   } else if (currDate >= new Date(2024, 7, 15, 23, 59, 59)) {
//     return ["Pichavaram", "Saranda", "Sundarbans", "Wayanad"];
//   }
// }

const Voting = () => {
  const [isVoted, setIsVoted] = useState(false);
  const [user, setUser] = useState({});
  const [selectedCandidates, setSelectedCandidates] = useState({ sec: [], dysec: [] });
  const [nominees, setNominees] = useState({ sec: [], dysec: [] });

  const { session } = useContext(AppContext);

  // Check Box Selection handling
  const handleCheckboxChange = (candidate) => {
    if (candidate.position === "Secretary") {
      setNominees({ ...nominees, sec: [candidate] });
    } else if (candidate.position === "Deputy Secretary") {
      setNominees({
        ...nominees,
        dysec: [candidate],
      });
    }
  };
  useEffect(() => {
    if (session.accessToken) {
      getProfileDetails().then((response) => {
        setUser(response);
      });
      // .then(() => {
      //   setLoading(false);
      // })
      // .catch((err) => {
      //   setLoading(false);
      //   throw err;
      // });
    }
  }, [session]);

  // Filtering of the nominees
  useEffect(() => {
    if (user.house !== undefined) {
      // filter the candidates based on the house of user
      const sec = [];
      const dysec = [];

      for (let i = 0; i < candidates.length; i++) {
        if (candidates[i].house.toLowerCase() === user.house.toLowerCase()) {
          if (candidates[i].position === "Secretary") {
            sec.push(candidates[i]);
          } else if (candidates[i].position === "Deputy Secretary") {
            dysec.push(candidates[i]);
          }
        }
        setSelectedCandidates({ sec, dysec });
      }
    }
  }, [user]);

  // Voting function
  const submitVote = async () => {
    if (nominees.sec.length === 0 && nominees.dysec.length === 0) {
      alert("Please select atleast one candidate");
      return;
    }

    // if (selectedCandidates.sec.length > 0 && nominees.sec.length == 0) {
    //   alert("Please select atleast one candidate of Secretary");
    //   return;
    // }
    //
    // if (selectedCandidates.dysec.length > 0 && nominees.dysec.length == 0) {
    //   alert("Please select atleast one candidate of Deputy Secretary");
    //   return;
    // }

    const voted = await voteElectedCandidates(user, nominees);
    if (voted === true) {
      setIsVoted(true);
      alert("Vote was submitted. Thank You!");
    } else if (voted === "voted") {
      setIsVoted(true);
      alert("Already voted");
    } else {
      alert("Something went wrong. Please try again");
    }
  };

  // User Data Fetching
  // TODO: Add user in the context
  return (
    <Layout>
      {!(currDate >= startDate && currDate <= endDate) ? (
        <div>
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ height: "70vh" }}
          >
            <h5 className="text-center">
              Voting window has been closed. If you think this is a mistake,{" "}
              <a
                className="text-black"
                href="mailto:student-affairs@study.iitm.ac.in,webops@study.iitm.ac.in"
              >
                let us know
              </a>
            </h5>
          </div>
        </div>
      ) : (
        <div>
          {user.voted || isVoted ? (
            <div>
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <h5 className="text-center">
                  You've already voted! If you think this is a mistake,{" "}
                  <a
                    className="text-black"
                    rel="noreferrer"
                    href="mailto:student-affairs@study.iitm.ac.in,webops@study.iitm.ac.in"
                    target={"_blank"}
                  >
                    let us know
                  </a>
                </h5>
              </div>
            </div>
          ) : (
            <>
              {/* <div className="w-100 pt-2 d-flex bg-color-gold justify-content-center align-items-center">
                <Nav className="w-80 bg-color-gold" justify variant="tabs" onSelect={select}>
                  {navItems.map((item) => (
                    <Nav.Item className="vote-nav-item" key={item.id}>
                      <Nav.Link
                        className="text-decoration-none"
                        key={item.id}
                        id={item.id}
                        eventKey={item.id}
                        active={step === item.id}
                        disabled={step !== item.id}
                      >
                        {item.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div> */}

              <div className="w-100 bg-color-maroon d-flex justify-content-center align-items-center flex-column py-5">
                <h2 className="text-center mb-5">
                  Vote for {user.house} House Candidates for UHC 2024
                </h2>
                <div className="d-flex flex-column justify-content-center flex-wrap flex-sm-row">
                  <div className="w-100 d-flex justify-content-center align-items-center fw-bold fs-5">
                    Secretary Nominees:
                  </div>
                  {selectedCandidates.sec.length > 0 &&
                    selectedCandidates.sec.map((candidate) => {
                      const checked = nominees.sec.includes(candidate);
                      return (
                        <div
                          className={`card mb-3 m-3 bg-color-gold ${
                            checked ? "border-4 border-white shadow" : "border-0"
                          }`}
                          key={candidate.email}
                          style={{
                            width: "20rem",
                            cursor: "pointer",
                            transition: "transform 0.2s ease, border 0.3s ease",
                            transform: checked ? "scale(1.05)" : "scale(1)",
                          }}
                          onClick={() => handleCheckboxChange(candidate)}
                        >
                          <img
                            className="card-img-top"
                            style={{ height: "240px", objectFit: "cover" }}
                            src={
                              "https://drive.google.com/thumbnail?id=" +
                              candidate.profilePhoto.split("id=")[1]
                            }
                            alt="Card cap"
                          />
                          <div className="card-body text-center">
                            <h5 className="card-title">{candidate.name}</h5>
                            <p className="small">{candidate.email}</p>
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents card click event
                                window.open(candidate.menifesto, "_blank");
                              }}
                              className="btn px-3 bg-color-maroon text-white"
                            >
                              <FontAwesomeIcon icon={faFile} /> Manifesto
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="d-flex flex-column mt-5 justify-content-center flex-wrap flex-sm-row">
                  <div className="w-100 d-flex justify-content-center align-items-center fw-bold fs-5">
                    Deputy Secretary Nominees:
                  </div>

                  {selectedCandidates.dysec.length > 0 &&
                    selectedCandidates.dysec.map((candidate) => {
                      const checked = nominees.dysec.includes(candidate);
                      return (
                        <div
                          className={`card mb-3 m-3 bg-color-gold ${
                            checked ? "border-4 border-white shadow" : "border-0"
                          }`}
                          key={candidate.email}
                          style={{
                            width: "20rem",
                            cursor: "pointer",
                            transition: "transform 0.2s ease, border 0.3s ease",
                            transform: checked ? "scale(1.05)" : "scale(1)",
                          }}
                          onClick={() => handleCheckboxChange(candidate)}
                        >
                          <img
                            className="card-img-top"
                            style={{ height: "240px", objectFit: "cover" }}
                            src={
                              "https://drive.google.com/thumbnail?id=" +
                              candidate.profilePhoto.split("id=")[1]
                            }
                            alt="Card cap"
                          />
                          <div className="card-body text-center">
                            <h5 className="card-title">{candidate.name}</h5>
                            <p className="small">{candidate.email}</p>
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents card click event
                                window.open(candidate.menifesto);
                              }}
                              className="btn px-3 bg-color-maroon text-white"
                            >
                              <FontAwesomeIcon icon={faFile} /> Manifesto
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <button
                  className="btn my-4 auth-btn px-4 text-white"
                  onClick={async () => {
                    if (window.confirm(`Are you sure you want to submit?`)) {
                      await submitVote();
                    } else {
                      alert("Vote was not submitted. Please try again.");
                    }
                  }}
                >
                  Submit your vote!
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </Layout>
  );
};

export default Voting;
