import React from "react";
import { uhcResults, uhcResultsArr } from "resources/UHCresults";
import Layout from "../components/Layout";
import { Chart } from "react-google-charts";
import ScrollFade from "components/container/ScrollFade";
import Container from "components/Container";

const UHCResutlts = () => {
  let obj = {};

  uhcResultsArr.map((result) => {
    // house obj
    if (!obj[result.house]) {
      obj[result.house] = result.votes;
    } else {
      obj[result.house] = obj[result.house] + result.votes;
    }
  });
  const data = Object.entries(obj);
  data.unshift(["Houses", "Votes"]);

  const options = {
    title: "",
  };

  return (
    <Layout>
      <Container bgColor="bg-color-maroon">
        <ScrollFade>
          <p className="text-white text-center text-capitalize display-4 mt-2">
            UHC 2024 Election Results
          </p>

          {/* <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/DordJ8Ep1bI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
          {/* <p className="text-center text-white mt-3">Click on the candidate to know more</p> */}
        </ScrollFade>
      </Container>
      <Container>
        <table className="table table-striped table-sm table-bordered shadow">
          <thead>
            <tr>
              <th scope="col">House name</th>
              <th scope="col">Secretary winner</th>
              <th scope="col">Dep secretary winner</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(uhcResults)
              .sort()
              .map((key) => {
                const [key1, key2] = uhcResults[key];

                const sec = key1.position === "Secretary" ? key1 : key2;
                const dysec = key1.position === "Deputy Secretary" ? key1 : key2;

                // const memebers = Object.keys(uhcResults[key]).map(() => uhcResults[key]);
                // const [key1] = Object.entries(memebers[0].sec);
                // const [key2] = Object.entries(memebers[0].dysec);
                return (
                  <tr key={key}>
                    <td>
                      <b>{key.toUpperCase()}</b>
                    </td>
                    <td className="table-responsive">
                      <a href={"mailto:" + sec.email}>{sec.name}</a>{" "}
                      <span className="fw-bold">{sec.votes}</span>
                    </td>
                    <td>
                      <div className="table-responsive">
                        <a href={"mailto:" + dysec.email}>{dysec.name}</a>{" "}
                        <span className="fw-bold">{dysec.votes}</span>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Container>
      {/* <div className="table-responsive container-lg">
        
      </div> */}
      <Container bgColor="bg-color-maroon">
        <ScrollFade>
          <p className="text-white text-center text-capitalize display-6 mt+-2">
            Voter turnout per house
          </p>
        </ScrollFade>

        {/* <div className="rounded"> */}
        <Chart chartType="PieChart" data={data} options={options} width={"100%"} height={"500px"} />
        {/* </div> */}
      </Container>
    </Layout>
  );
};

export default UHCResutlts;
