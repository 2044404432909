import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  setDoc,
  orderBy,
  updateDoc,
  increment,
} from "@firebase/firestore";

import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { getMessaging, onMessage } from "firebase/messaging";
import { addToCache, getFromCache } from "./cache";

export const getProfileDetails = async () => {
  var res = await getFromCache("user");
  if (res != false) {
    return res;
  } else {
    console.log("getting");
    const auth = getAuth();
    const user = auth.currentUser;
    const { displayName: userName, email } = user;
    const db = getFirestore();
    try {
      const userDoc = await getDoc(doc(db, "users", email));
      const data = userDoc.data();
      addToCache("user", {
        userName,
        email,
        ...data,
      });
      return {
        userName,
        email,
        ...data,
      };
    } catch (err) {
      signOut(auth);
      alert("Sign in using your student login email");
      return {};
    }
  }
};

export const signInFirebase = async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  // provider.setCustomParameters({
  //   hd: "ds.study.iitm.ac.in",
  // });
  return signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // ...

      return {
        status: "success",
        token,
        user,
      };
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
      return {
        status: "error",
        errorCode,
        errorMessage,
        email,
        credential,
      };
    });
};

export const updateFCMTokenToDB = ({ fcm }) => {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  const { email } = user;
  return setDoc(doc(db, "subscription", email), { fcm }).catch((err) => {
    alert(err);
  });
};

export const onMessageListener = () => {
  const messaging = getMessaging();
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};

const getSec = async (house) => {
  const db = getFirestore();
  const q = query(collection(db, `elections/${house}/secretary`), orderBy("name"));
  const secDoc = await getDocs(q);
  let sec = [];
  secDoc.forEach((doc) => sec.push(doc.data()));
  return sec;
};

export const getSPCNominees = async () => {
  const db = getFirestore();
  const q = query(collection(db, `/spcnominees`));
  const spcDoc = await getDocs(q);
  let spc = [];
  spcDoc.forEach((doc) => spc.push(doc.data()));
  return spc;
};

const getDySec = async (house) => {
  const db = getFirestore();
  const q = query(collection(db, `elections/${house}/deputy-secretary`), orderBy("name"));
  const dySecDoc = await getDocs(q);
  let dySec = [];
  dySecDoc.forEach((doc) => dySec.push(doc.data()));
  return dySec;
};

export const getSelectedCandidates = async () => {
  const res = await getFromCache("selected-candidates");
  if (res !== false) {
    console.log("from cache");
    return res;
  }
  const db = getFirestore();
  const q = query(collection(db, "elected-candidates"));
  const selectedCandidatesDoc = await getDocs(q);
  let selectedCandidates = [];
  selectedCandidatesDoc.forEach((doc) => selectedCandidates.push(doc.data()));
  addToCache("elected-candidates", selectedCandidates);
  return selectedCandidates;
};

export const voteElectedCandidates = async (user, nominiees) => {
  const db = getFirestore();
  // is Authenticated
  if (!user) {
    return new Error("User is not authenticated");
  }

  try {
    // get user
    const auth = getAuth();
    const user1 = auth.currentUser;
    const { displayName: userName, email } = user1;
    const userDoc = await getDoc(doc(db, "users", email));
    const data = userDoc.data();
    if (data.voted) {
      addToCache("user", {
        userName,
        email,
        ...data,
      });
      alert("Already voted");
      return "voted";
    }

    await updateDoc(doc(db, "users", user.email), {
      voted: true,
    });
    const cachedUser = getFromCache("user");
    addToCache("user", {
      ...cachedUser,
      voted: true,
    });

    // one for each candidate
    if (nominiees.sec.length > 0) {
      await updateDoc(doc(db, "elected-candidates", nominiees.sec[0].email), {
        votes: increment(1),
      });
    }
    if (nominiees.dysec.length > 0) {
      await updateDoc(doc(db, "elected-candidates", nominiees.dysec[0].email), {
        votes: increment(1),
      });
    }

    // update user.voted in cache
    addToCache("user", {
      ...user,
      voted: true,
    });

    return true;
  } catch (err) {
    alert(err);
    console.error(err);
    return false;
  }
};

export const voteForSPC = async (res) => {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  // is Authenticated
  if (!user) {
    return new Error("User is not authenticated");
  }

  try {
    await updateDoc(doc(db, "users", user.email), {
      voted: true,
    });
    await updateDoc(doc(db, "elected-candidates", res.email), {
      votes: increment(1),
    });

    return true;
  } catch (err) {
    alert(err);
    console.error(err);
    return false;
  }
};

export const updateVoteOfSpc = (email) => {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  // is Authenticated
  if (!user) {
    return new Error("User is not authenticated");
  }
  updateDoc(doc(db, "spcnominees", email), { votes: increment(1) }).catch((err) => {
    alert(err);
  });
  updateDoc(doc(db, "users", email), { votedForSPC: true }).catch((err) => {
    alert(err);
  });

  return true;
};

const getWebAd = async (house) => {
  const db = getFirestore();
  const q = query(collection(db, `elections/${house}/web-admin`), orderBy("name"));
  const webAdDoc = await getDocs(q);
  let webad = [];
  webAdDoc.forEach((doc) => webad.push(doc.data()));
  return webad;
};

const getMentors = async (house) => {
  const db = getFirestore();
  const q = query(collection(db, `elections/${house}/mentor`), orderBy("name"));
  const mentorDoc = await getDocs(q);
  let mentors = [];
  mentorDoc.forEach((doc) => {
    mentors.push(doc.data());
  });
  return mentors;
};

export const getElectionCandidates = async () => {
  var res = await getFromCache("nominations");
  if (res !== false) {
    return res;
  }
  const user = await getProfileDetails();
  const house = user.house.split(" ")[0].toLowerCase();
  const sec = await getSec(house);
  const dysec = await getDySec(house);
  // const webad = await getWebAd(house);
  // const mentor = await getMentors(house);
  addToCache("nominations", { ...user, sec, dysec });
  return { ...user, sec, dysec };
};

export const getUserData = async () => {
  var res = await getFromCache("user");
  if (res !== false) {
    return res;
  }
  const user = await getProfileDetails();
  addToCache("user", user);
  return user;
};

export const updateVote = async (r) => {
  const db = getFirestore();
  const voteDoc = doc(db, "elections", "votes");
  const userDoc = doc(db, "users", r.email);
  var data = {};
  data[r.email.split("@")[0]] = r;
  updateDoc(userDoc, { voted: true })
    .then(() => {
      updateDoc(voteDoc, data)
        .then(async (r) => {
          var user = await getFromCache("user");
          var nominations = await getFromCache("nominations");
          nominations.voted = true;
          user.voted = true;
          addToCache("user", user);
          addToCache("nominations", nominations);
          return true;
        })
        .catch((e) => {
          updateDoc(userDoc, { voted: false });
          return false;
        });
    })
    .catch((e) => false);
};
