import { initializeApp } from "firebase/app";

export const initFirebase = () => {
  initializeApp({
    // apiKey: "AIzaSyDB3hmzWVC4PU3c0lOKYtwPmNruarkA6W8",
    // authDomain: "iitmbs-students.firebaseapp.com",
    // projectId: "iitmbs-students",
    // storageBucket: "iitmbs-students.appspot.com",
    // messagingSenderId: "305171223408",
    // appId: "1:305171223408:web:0dd85f06684ce6ad9f35b5",
    // measurementId: "G-0S89RVFQ5W",
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  });
};
