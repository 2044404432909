import Container from "../components/Container";
import Layout from "../components/Layout";
import Timeline from "../components/timeline/timeline";

const timelines = [
  {
    title: "Online Nominations Starts",
    date: "Closes by 8 Aug 2024",
    description:
      "Online nomination of Secretaries & Deputy Secretaries, closes by Monday (08-08-2024)",
    pulse: [null, new Date(2024, 7, 8, 23, 59, 59)],
  },
  {
    title: "Individual Campaigning",
    date: "8 - 14 Aug 2024",
    description: "Individual Campaigning starts by 8th Aug, and ends by 14th Aug",
    pulse: [new Date(2024, 7, 8, 0, 0, 0), new Date(2024, 7, 14, 23, 59, 59)],
  },
  {
    title: "Public Debates - YouTube Live",
    date: "12 - 14 Aug 2024",
    description:
      "Public Debate will be streamed live on IITM-Online BS Degree official YouTube, starts on 12th Aug and ends by 14th Aug",
    pulse: [new Date(2024, 7, 12, 0, 0, 0), new Date(2024, 7, 14, 23, 59, 59)],
  },
  {
    title: "House Council Elections",
    date: "13 - 15 Aug 2024",
    description:
      "The official House Council Elections for 2024 will run on these days: <br/> <span style='font-weight: bold'>1. Bandipur/Corbett/Gir/Kanha (13.08.2024)</span> <br/> <span style='font-weight:bold'> 2. Kaziranga/Nallamala/Namdapha/Nilgiri (14.08.2024)</span> <br/> <span style='font-weight:bold'>3. Pichavaram/Saranda/Sundarbans/Wayanad (15.08.2024)</span>",
    pulse: new Date(2024, 7, 15, 23, 59, 59),
  },
  {
    title: "Election Results & Oath taking",
    date: "17 Aug 2024",
    description:
      "The 2024 House Council Electrions results will be announced on 17th Aug 2024, followed by the oath taking ceremony",
    pulse: new Date(2024, 7, 17, 23, 59, 59),
  },
];

const ElectionsPage = () => {
  return (
    <Layout>
      <Container bgColor="bg-color-maroon">
        <p className="display-5 text-center text-white">UHC Elections 2024</p>
      </Container>

      <div className="px-4">
        <div
          className="d-flex flex-column gap-3 mx-auto mt-4"
          style={{ maxWidth: "1020px", width: "100%" }}
        >
          <h2 className="text-color-maroon text-start">
            Election of Students' Representatives 2024
          </h2>
          <div className="d-flex flex-column">
            <p>
              Date of Upper House Council (UHC) election 2024 - <b>13 - 15 Aug, 2024</b>
            </p>
            <p>
              Elections of the posts in the Upper House Council are hereby called for. Nominations
              are invited for the certain posts from <a href="#annexure-i">eligible students</a> -{" "}
              <a href="#eligible-posts">Click Here</a>
            </p>
            <p>
              {" "}
              In case of queries email to{" "}
              <a href="mailto:student-affairs@study.iitm.ac.in">
                student-affairs@study.iitm.ac.in
              </a>{" "}
              with subject <b>"Elections"</b>, and in case of any complaints or violations send mail
              to{" "}
              <a href="mailto:students-grievance@study.iitm.ac.in">
                students-grievance@study.iitm.ac.in
              </a>{" "}
              with subject <b>"Elections"</b>
            </p>
          </div>
        </div>

        {/* add code here */}
        <div
          className="d-flex flex-column gap-3 mx-auto mt-4"
          style={{ maxWidth: "1020px", width: "100%" }}
        >
          <h2 className="text-color-maroon text-start">UHC Elections 2024 Schedule</h2>
          <div>{Timeline({ timelines })}</div>
        </div>

        <div
          className="d-flex flex-column gap-3 mx-auto mt-4"
          style={{ maxWidth: "1020px", width: "100%" }}
          id="eligible-posts"
        >
          <h2 className="text-color-maroon text-start">House Council Elections 2024</h2>
          <p>Nominations are invited for the following posts from eligible students</p>
          <p>
            Eligible candidates can fill their nomination in the google form:{" "}
            <a href="https://forms.gle/UJDFWuN6DFwQPp5y5" target="_blank" rel="noreferrer">
              https://forms.gle/UJDFWuN6DFwQPp5y5
            </a>
          </p>
          <div className="py-5 px-4 border rounded d-flex flex-column gap-2">
            <h3 className="text-color-maroon text-center">HOUSE COUNCILS</h3>

            <div className="container text-center">
              <div className="row" style={{ minHeight: "200px" }}>
                <div className="col h-100 pt-2 w-100" style={{ minHeight: "200px" }}>
                  <div className="d-flex flex-column gap-1 justify-content-center">
                    <p>
                      POST <i>of</i> <b style={{ paddingLeft: "2px" }}>SECRETARY</b>
                    </p>
                    <p className="mx-auto" style={{ maxWidth: "200px" }}>
                      Available <b>12 positions</b> - <i>one position for each house</i>, with a
                      total of 12 houses.
                    </p>
                    <p>
                      <b>Electorate:</b> <i>All House Members</i>
                    </p>
                  </div>
                </div>
                <div
                  className="col d-flex flex-column gap-1 border border-top-0 border-right-0 border-bottom-0 h-100 pt-2"
                  style={{ minHeight: "200px" }}
                >
                  <div className="col h-100 pt-2 w-100" style={{ minHeight: "200px" }}>
                    <div className="d-flex flex-column gap-1 justify-content-center">
                      <p>
                        POST <i>of</i> <b style={{ paddingLeft: "2px" }}>DEPUTY SECRETARY </b>
                      </p>
                      <p className="mx-auto" style={{ maxWidth: "200px" }}>
                        Available <b>12 positions</b> - <i>one position for each house</i>, with a
                        total of 12 houses.
                      </p>
                      <p>
                        <b>Electorate:</b> <i>All House Members</i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex flex-column gap-3 mx-auto mt-4"
          style={{ maxWidth: "1020px", width: "100%" }}
          id="annexure"
        >
          <h2 className="text-color-maroon text-start">Annexure & Notes</h2>
          <p>
            <b>Note:</b> Duties of the representatives can be found in the{" "}
            <a
              href="https://docs.google.com/document/d/e/2PACX-1vQB7SYIXQPJr0-WcfekVVSt488MdlkNzRUPacbRh2QgOALXcinPybopWIFlY83tdr_mH1QtrhCIsFUq/pub"
              target="_blank"
              rel="norefeerrer"
            >
              Students' Handbook
            </a>
          </p>
          <p>
            For eligibility criteria, check the <a href="#annexure-i">Annexure - I</a> below.
            Eligible candidates can fill their nomination in the google form:{" "}
            <a href="https://forms.gle/UJDFWuN6DFwQPp5y5" target="_blank" rel="noreferrer">
              https://forms.gle/UJDFWuN6DFwQPp5y5
            </a>
          </p>

          <div className="py-3 px-4 border rounded d-flex flex-column gap-2" id="annexure-i">
            <h3 className="text-color-maroon text-center">Annexure - I</h3>

            <div className="container text-start">
              <h3 className="fs-5">Eligibility Criteria for Secretary/Deputy Secretary:</h3>
              <ol>
                <li> Should be 25 years of age or less. </li>
                <li> Should have a CGPA of 7.5 or more. </li>
                <li> Should not be in RKA (Registration Keep Alive). </li>
                <li>
                  {" "}
                  Should not have been found guilty of Academic Malpractice or Non-academic
                  Misconduct.{" "}
                </li>
                <li>
                  {" "}
                  Should have been a Group Leader or should have held any POR at least for a term.{" "}
                </li>
                <li> Should have completed at least 4 academic courses. </li>
                <li> Should not have held a Secretary post before. </li>
                <li>
                  {" "}
                  The nominee is expected to stay on rolls as a student for 3 consecutive terms
                  including the term in which they have been elected.{" "}
                </li>
                <li>
                  {" "}
                  The above eligibility criteria may be waived by the Student Affairs committee on a
                  case to case basis.{" "}
                </li>
              </ol>
            </div>
          </div>

          <p>
            {" "}
            In case of queries email to{" "}
            <a href="mailto:student-affairs@study.iitm.ac.in">
              student-affairs@study.iitm.ac.in
            </a>{" "}
            with subject <b>"Elections"</b>, and in case of any complaints or violations send mail
            to{" "}
            <a href="mailto:students-grievance@study.iitm.ac.in">
              students-grievance@study.iitm.ac.in
            </a>{" "}
            with subject <b>"Elections"</b>
          </p>
        </div>

        <div className="mb-4"></div>
      </div>
    </Layout>
  );
};

export default ElectionsPage;
