import { Fragment } from "react";
import "./timeline.css";

const TimelineStep = ({ title, date, description, pulse }) => {
  const now = new Date();
  let pulse_bg = "";
  if (Array.isArray(pulse)) {
    const start = pulse[0] || now;
    const end = pulse[1];
    if (start <= now && now <= end) {
      pulse_bg = "timeline-item-active";
    } else if (start >= now) {
      pulse_bg = "timeline-item-upcoming";
    } else {
      pulse_bg = "timeline-item-past";
    }
  } else {
    const date = pulse;
    if (date < now) pulse_bg = "timeline-item-past";
    else if (date.setHours(0, 0, 0) === now.setHours(0, 0, 0)) pulse_bg = "timeline-item-active";
    else if (date > now) pulse_bg = "timeline-item-upcoming";
  }

  return (
    <Fragment key={date}>
      <li className={`timeline-item ${pulse_bg}`}>
        <h5 className="fw-bold">{title}</h5>
        <p className="text-muted mb-2 fw-bold">{date}</p>
        <p className="text-muted" dangerouslySetInnerHTML={{ __html: description }}></p>
      </li>
    </Fragment>
  );
};

function Timeline({ timelines }) {
  return (
    <>
      <section className="py-5 px-4 border rounded">
        <ul className="timeline d-flex flex-column gap-3">
          {timelines.map((t) => TimelineStep(t))}
        </ul>
      </section>
    </>
  );
}

export default Timeline;
