import Layout from "components/Layout";
import Container from "components/Container";
import electionsImg from "assets/images/2024_elections.png";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <Layout>
      <div className="py-4 bg-color-maroon d-flex justify-content-center">
        <img src={electionsImg} className="header-img" alt="header img" />
        {/* <p className="p-0 mt-4 display-6 text-center">IITM BS Elections 2022</p> */}
      </div>
      <Container>
        <p>
          It is an honor and a privilege for the IITM BS Degree Student Affairs and the Election
          Committee to organize the Upper House Council (UHC) Elections. We endeavour to conduct
          fair and equal voting access that matches the best person to each of the UHC positions.
        </p>
        <p>
          The IITM BS Degree program is structured in a dynamic manner with a plethora of activities
          around the curriculum. The cohort of our learners brings the best of the diversity of
          India and abroad. Even more, they are distributed across various age groups & subjects,
          adding more flavors to the mix. Within such a vibrant community, leadership is surely an
          opportunity and a challenge.
        </p>
      </Container>
      <Container bgColor="bg-color-maroon">
        <p>
          The right leadership of the IITM BS Degree Students UHC is of utmost importance. That can
          ensure better activities and opportunities for all our students.
        </p>
        <p>
          In each phase of the elections, we will be voting for the UHC candidates of four houses:
        </p>
      </Container>
      <Container>
        <p>
          Phase 1 - <span className="fw-bold"> 13 Aug</span>: Bandipur, Corbett, Gir, Kanha <br />
          Phase 2 - <span className="fw-bold"> 14 Aug </span>: Kaziranga/Nallamala/Namdapha/Nilgiri
          (14.08.2024) <br />
          Phase 3 - <span className="fw-bold"> 15 Aug </span>: Pichavaram/Saranda/Sundarbans/Wayanad
          (15.08.2024) <br />
        </p>

        <p>
          This website has been developed to provide you candidate information and a voting form
          that allows you to vote for the candidates of your choice.
        </p>
        <p>
          Please do share your suggestions and feedback as it helps us make our processes and
          systems even better.
        </p>
        <div className="d-flex justify-content-center w-100">
          <Link to="/vote" className="btn auth-btn px-4 text-white">
            {/* Know your Candidates */}
            Start Voting!
          </Link>
        </div>
      </Container>
    </Layout>
  );
};

export default HomePage;
